import React, { Component } from 'react';
import styled from 'styled-components';
import { Link, navigate } from 'gatsby';
import Div100vh from 'react-div-100vh';

import Seo from '../components/seo';

const AboutPageContainer = styled.div`
  a {
    color: inherit;
    text-decoration-color: rgba(255, 255, 255, 0.5);
  }

  a:hover {
    border-bottom: 1px solid white;
  }

  .container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100vh;

    background-color: rgb(1, 2, 172);
    font-size: calc(12px + 0.5vw);
    font-family: 'Press Start 2P', cursive;
    line-height: 1.34;
    color: white;

    @media only screen and (min-width: 800px) {
      font-size: 16px;
    }
  }

  .msg {
    width: 90vw;
    max-width: 1024px;
    margin: 0 auto;
  }

  p {
    text-align: left;
  }

  h3 {
    font-size: inherit;
    font-weight: normal;

    &:not(:first-child) {
      margin-top: 2.5em;
    }
  }

  .continue {
    text-align: center;
    margin: 0;
  }

  .continue a {
    display: inline-block;
    text-decoration: none;
    padding: 1em;

    &:hover {
      border: none;
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .highlight {
    color: rgb(1, 2, 172);
    background-color: rgb(172, 173, 168);
    padding: 3px;
    text-align: center;
    width: 150px;
  }

  .blink {
    animation: blink 1s steps(2, start) infinite;
  }

  @keyframes blink {
    to {
      visibility: hidden;
    }
  }
`;

class AboutPage extends Component {
  constructor(props) {
    super(props);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }
  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }
  handleKeyPress(event) {
    if (event.ctrlKey || event.shiftKey || event.altKey || event.metaKey) {
      return;
    }

    navigate('/work/', {
      state: { fromAbout: true },
    });
  }

  render() {
    return (
      <AboutPageContainer>
        <Seo title="ABOUT" />

        <Div100vh className="container">
          <div className="msg" align="center">
            <h3 className="highlight">About</h3>
            <p>
              racertrash is a freeform collective of artists br0adcasting
              reinterpreted:media.etc for charitable causes // your viewing
              pleasure
            </p>
            <p>* Black Lives Matter.</p>
            <p>* Pfannkuchen sind Liebchen.</p>

            <h3 className="highlight">Contact</h3>
            <p>
              DMs open on{' '}
              <a
                href="https://twitter.com/racertrash"
                target="_blank"
                rel="noopener noreferrer"
              >
                twitter
              </a>{' '}
              and{' '}
              <a
                href="https://instagram.com/racertrash"
                target="_blank"
                rel="noopener noreferrer"
              >
                instagram
              </a>
              , or email us{' '}
              <a href="mailto:speed@racertrash.com">speed@racertrash.com</a>
            </p>
            <br />
            <p className="continue">
              <Link to="/work/" state={{ fromAbout: true }}>
                Press any key to continue&nbsp;<span className="blink">_</span>
              </Link>
            </p>
          </div>
        </Div100vh>
      </AboutPageContainer>
    );
  }
}

export default AboutPage;
